import React, { Component } from "react";
import config from './config';
import { motion } from "framer-motion";
import Card from '@material-ui/core/Card';
import TopBarTitle from './TopBarTitle';

import MoreCashbackPopup from './popup/MoreCashbackPopup';
import CircularProgress from '@material-ui/core/CircularProgress';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import InternetNotAvailable from './InternetNotAvailable';
import { Link } from "react-router-dom";
import AdSense from 'react-adsense';

export default class Wallet extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      apiResponse: '',
      isOpenPopup: false,
      cashbackType: '',
      fbsubmitPaymentRequest: false
    }

    this.goBack = this.goBack.bind(this);
    this.openPopup = this.openPopup.bind(this);
    this.closePopup = this.closePopup.bind(this);
    this.redirectTo = this.redirectTo.bind(this);
    this.submitPaymentRequest = this.submitPaymentRequest.bind(this);
  }



  getWalletData() {
    var requestParams = { "fsAction": "getWalletCount" };
    config.fetchInformation(requestParams).then((responseJson) => {
      this.setState({
        apiResponse: responseJson,
        isLoading: false,
      });
    });
  }

  componentWillMount() {
    this.getWalletData();
  }

  goBack() {
    this.props.history.push({
      pathname: '/',
    });
  }

  openPopup(cashbackType) {
    if (cashbackType === 'instant-cash') {
      this.setState({ 'isOpenPopup': true, cashbackType: 'info-instant-cash' });
    } else if (cashbackType === 'my-cash') {
      this.setState({ 'isOpenPopup': true, cashbackType: 'info-my-cash' });
    } else if (cashbackType === 'pending-cash') {
      this.setState({ 'isOpenPopup': true, cashbackType: 'info-pending-cash' });
    }
  }

  closePopup() {
    this.setState({ 'isOpenPopup': false, cashbackType: '' });
  }

  redirectTo(e, screen) {
    e.preventDefault();
    this.props.history.push({
      pathname: '/' + screen,
    });
  }
  submitPaymentRequest(e) {
    e.preventDefault();
    this.setState({ fbsubmitPaymentRequest: true });
    var requestParams = {
      "fsAction": "submitPaymentRequest",
      "fiAmount": this.state.apiResponse.foWalletCount.foInstantCash.fiCashback,
    };
    config.fetchInformation(requestParams).then((responseJson) => {
      if (responseJson.fbIsError) {
        toast.error(responseJson.fsMessage, {
          position: "top-center",
          autoClose: 8000
        });
        this.setState({ fbsubmitPaymentRequest: false });
      } else {
        toast.success(responseJson.fsMessage, {
          position: "top-center",
          autoClose: 8000
        });
        this.setState({ fbsubmitPaymentRequest: false });
      }
    });
  }

  render() {

    /* No Internet */
    if (!navigator.onLine) {
      return (
        <InternetNotAvailable />
      )
    }

    var fiTotalInstantCash = 0;
    var fiTotalMyCash = 0;
    var fiTotalPendingCash = 0;
    var fsUserWalletName = '';

    if (this.state.apiResponse != '') {
      fiTotalInstantCash = this.state.apiResponse.foWalletCount.foInstantCash.fiTotalUsableCash;
      fiTotalMyCash = this.state.apiResponse.foWalletCount.fiTotalMyCash;
      fiTotalPendingCash = this.state.apiResponse.foWalletCount.fiTotalPendingCash;
      fsUserWalletName = this.state.apiResponse.fsUserWalletName;
    }

    return (
      <motion.div
        initial="initial"
        animate="in"
        exit="out"
        variants={config.pageAnimation}
        transition={config.pageTransition}
      >
        <div className="AppDashboard">
          <div className="topAndSideBar">
            <TopBarTitle strTitle="Wallet" goBack={this.goBack} />
          </div>

          <div className="AppContent isNormalView isWalletPage isTopBarOnTop">
            <ToastContainer theme="colored" />
            {this.state.fbsubmitPaymentRequest ? <div className="buy-voucher-loader"><div className="apiCallLoader"><CircularProgress color="secondary" /></div></div> : ''}
            {this.state.isOpenPopup ? <MoreCashbackPopup popupButtonText='Close'
              secondLineText=""
              handleClose={this.closePopup}
              cashbackType={this.state.cashbackType}
              openDialog={true} /> : ''}

            <div className="timelineList walletList">
              <div className="walletTop addButton">


                <div className="singleCouponTimeLine MyCashTimeLine">
                  <Card>
                    <div className="timeLineContent">
                      <div className="items2">
                        <h3 className="mt-0">Instant Cashback <span onClick={() => { this.openPopup('instant-cash') }} className=" walletHelpIcon material-icons-outlined">help</span></h3>
                      </div>
                      <div className="adsPrice textRight walletTime colorGreen">
                        Rs. {fiTotalInstantCash}
                      </div>
                      <div className="my-cash-details">
                        <span>
                          Congrats! You got Instant Cashback <b>Rs. {fiTotalInstantCash}</b><br />
                          Real Cash you can use on any Voucher to make<br />
                          it Free, or Withdraw into your UPI ac / {fsUserWalletName}.
                        </span>
                        <div style={{ 'display': 'flex', flexDirection: 'column', 'justifyContent': 'space-between' }}>
                          <a href="#" className="history-link" onClick={(e) => { this.submitPaymentRequest(e) }} style={{ alignSelf: 'start' }}>
                            Credit to {fsUserWalletName}/UPI (Min Rs.1000)</a>
                          <a href="#" className="history-link" onClick={(e) => { this.redirectTo(e, 'history-instant-cash') }}>
                            View History
                          </a>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>


                <div className="singleCouponTimeLine MyCashTimeLine">
                  <Card>
                    <div className="timeLineContent">
                      <div className="items2">
                        <h3 className="mt-0">{config.getGlobalCashbackString()} <span onClick={() => { this.openPopup('my-cash') }} className=" walletHelpIcon material-icons-outlined">help</span></h3>
                      </div>
                      <div className="adsPrice textRight walletTime colorGreen">
                        Rs. {fiTotalMyCash}
                      </div>
                      <div className="my-cash-details">
                        <span>
                          Congrats! You got {config.getGlobalCashbackString()} Credit <b>Rs. {fiTotalMyCash}</b><br />
                          This Converts to Cash, using Instant Cashback<br />
                          coupons or Instant Discount in Restaurants.
                        </span>
                        <div>
                          <div style={{ 'display': 'flex', flexDirection: 'column' }}>
                            <a href="#" className="history-link" onClick={(e) => { this.redirectTo(e, 'history-mycash') }}>View History</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>





                <div className="singleCouponTimeLine MyCashTimeLine">
                  <Card>
                    <div className="timeLineContent">
                      <div className="items2">
                        <h3 className="mt-0">Cashback Pending <span onClick={() => { this.openPopup('pending-cash') }} className=" walletHelpIcon material-icons-outlined">help</span></h3>
                      </div>
                      <div className="adsPrice textRight walletTime colorGreen">
                        Rs {fiTotalPendingCash}
                      </div>
                      <div className="my-cash-details">
                        <span>
                          Coming Soon into your wallet <b>Rs. {fiTotalPendingCash}</b><br />
                          When you pay at Online Store, Usual Cashback<br />
                          will credit in about 60 days from purchase date.
                        </span>
                        <div>
                          <div style={{ 'display': 'flex', flexDirection: 'column' }}>
                            <a href="#" className="history-link" onClick={(e) => { this.redirectTo(e, 'history-pending-cash') }}>View History</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>

                {/* <div className="" style={{'margin':'15px 0px'}}>
                    <AdSense.Google
                        client={config.adsense.clientId}
                        slot={config.adsense.slotId}
                        style={{ display: 'block' }}
                        format="auto"
                        responsive="true"
                    />
                </div> */}

                <div className="singleCouponTimeLine MyCashTimeLine redirect-buttons">
                  <Card>
                    <div className="timeLineContent">
                      <div className="adsPrice textRight walletTime colorGreen">
                      </div>
                      <div className="my-cash-details">
                        <Link to="/">
                          <button>Shop Now</button>
                        </Link>
                        <Link to="/my-timeline">
                          <button>My Coupons</button>
                        </Link>
                      </div>
                    </div>
                  </Card>
                </div>


              </div>
            </div>
          </div>
        </div>
      </motion.div>
    )
  }
}