import React, { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import config from './config';
import ContinuePopup from './popup/ContinuePopup';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

const https = require('https');

export default function PaymentMethod(props) {
    
    const foGlobalSetting = JSON.parse(localStorage.getItem('foGlobalSetting'));
    const isPaymentPopupEnable = Object.keys(foGlobalSetting.foPaymentMethods).length==1?false:true;
    const paymentOptions = foGlobalSetting.foPaymentMethods;

    const [paymentData, setPaymentData] = useState({
        token: "",
        order: "",
        mid: "",
        amount: ""
    });
    const [loading, setLoading] = useState(false);

    const [paymentMethod, setPaymentMethod] = useState("");
    const [showSelectionError, setShowSelectionError] = useState("");
    const [isShowPaymentMethodPopup, setShowPaymentMethodPopup] = useState(true);


    function payFromKey(paymentKey){

        var requestParams = {
            "fsAction":"createOrder",
            "fsPaymentMethod":paymentKey
        };

        requestParams = { ...requestParams, ...props.orderDetails }

        config.fetchInformation(requestParams).then((responseJson)=>{
            if (responseJson.fbIsError === false) {
                if(paymentKey=='fsRazorPay'){
                    // displayRazorpay(paymentOptions.fsRazorPay,responseJson);
                    const { fsLink } = responseJson;
                    openLinkOnBrowser(fsLink);
                    props.backToMain();
                }
                if(paymentKey=='fsPaytm'){
                    initializePaytm(paymentOptions.fsPaytm,responseJson);
                }
                if(paymentKey=='fsWebviewPaymentBillDesk' || paymentKey=='fsWebviewPaymentJustPay'){
                    // initializeJustpay();
                    const { fsLink } = responseJson;
                    openLinkOnBrowser(fsLink);
                    props.backToMain();
                }
                // setTimeout(() => {
                //     props.backToMain();
                // }, 3000)
            } else {
                toast.error(responseJson.fsMessage, { position: "top-center", autoClose: 8000 });
                // props.backToMain();
                props.placeVoucherOrder({
                    STATUS: 'TXN_ERROR'
                })
            }
        });
    }

    useEffect(() => {
        if(!isPaymentPopupEnable){
            if(paymentOptions.fsRazorPay){
                payFromKey('fsRazorPay');
            }
            if(paymentOptions.fsPaytm){
                payFromKey('fsPaytm');
            }
            if(paymentOptions.fsWebviewPaymentBillDesk || paymentOptions.fsWebviewPaymentJustPay){
                payFromKey('fsWebviewPaymentBillDesk');
            }
        }
        // initializeJustpay();
        // initializePaytm();
        // displayRazorpay();
        return () => {
            // componentWillUnmount
        }
    }, []);

    const loadScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }


    const openLinkOnBrowser = (fsCouponLink) => {
        var fsProvider = config.getProviderReferral();
        if (fsProvider === 'M91APP') {
            try {
                window.JSBridgePlugin.openInBrowser(fsCouponLink);
            } catch (error) {
                console.log(error);
            }
        } else if (fsProvider === 'FATAKP') {
            try {
                window.ReactNativeWebView.postMessage(fsCouponLink);
            } catch (error) {
                console.log(error);
            }
        } else {
            try {
                window.app.openLinkOnBrowser(fsCouponLink);
            } catch (error) {
                console.log(error);
            }
        }

        if (config.getProviderSetting().fbIsPWAOpenOnWeb) {
            window.open(fsCouponLink, '_blank');
        }

    }

    // const initializeJustpay = () => {
    //     let foGlobalSetting = JSON.parse(localStorage.getItem('foGlobalSetting'));
    //     const { fsNextTransactiontTime } = foGlobalSetting;
    //     if (fsNextTransactiontTime > Date.now()) {
    //         props.placeVoucherOrder({
    //             STATUS: 'TXN_ERROR',
    //             RESPMSG: "You can try your next transaction after " + Math.ceil((((fsNextTransactiontTime - Date.now()) % 86400000) % 3600000) / 60000) + " minutes."
    //         });
    //     } else {
    //         fetch(foGlobalSetting.fsPaymentRequestUrl, { method: 'POST', body: JSON.stringify(props.orderDetails) })
    //             .then((response) => response.text())
    //             .then((responseText) => {
    //                 let response = JSON.parse(responseText); console.log(response);
    //                 const { fbIsError, fsMessage } = response;

    //                 const { fsNextTransactiontTime } = foGlobalSetting;

    //                 if (fsNextTransactiontTime > Date.now()) {
    //                     props.placeVoucherOrder({
    //                         STATUS: 'TXN_ERROR',
    //                         RESPMSG: "You can try your next transaction after " + Math.ceil((((fsNextTransactiontTime - Date.now()) % 86400000) % 3600000) / 60000) + " minutes."
    //                     });
    //                 } else {
    //                     if (fbIsError === false) {
    //                         foGlobalSetting['fsNextTransactiontTime'] = Date.now() + 180000;
    //                         localStorage.setItem('foGlobalSetting', JSON.stringify(foGlobalSetting));
    //                         props.backToMain();
    //                         const { fsLink } = response;
    //                         openLinkOnBrowser(fsLink);
    //                     } else {
    //                         // props.backToMain();
    //                         props.placeVoucherOrder({
    //                             STATUS: 'TXN_ERROR',
    //                             RESPMSG: fsMessage
    //                         })
    //                     }
    //                 }
    //             });
    //     }
    // }

    const initializePaytm = (paytmSetting,responseJson) => {
        let mid = paytmSetting.fsMID; // Merchant ID

        if (responseJson.fsTxtToken !== "") {
            setPaymentData({
                ...paymentData,
                token: responseJson.fsTxtToken,
                order: responseJson.fsOrderId,
                mid: mid,
                amount: parseFloat(props.orderDetails.fiTotalAmount)
            })
        }else{
            toast.error("Something is wrong on request to make payment", { position: "top-center", autoClose: 8000 });
        }
    }

    const displayRazorpay = (razorPaySetting,responseJson) => {

        const res = loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }

        // creating a new order
        // const result = await axios.post("http://localhost:5000/payment/orders");

        // Getting the order details back
        const options = {
            key: razorPaySetting.fsKeyId, // Enter the Key ID generated from the Dashboard
            amount: parseFloat(props.orderDetails.fiTotalAmount) * 100,
            currency: 'INR',
            description: props.orderDetails.fsDescription,
            // image: { logo },
            // order_id: responseJson.fsOrderId,
            // receipt: responseJson.fsOrderId,
            notes : {
                order_id : responseJson.fsOrderId
            },
            handler: async (response) => {

                // console.log('Payment Success', response);
                response.STATUS = "TXN_AWAITING";
                response.TXNID = response.razorpay_payment_id;
                // props.placeVoucherOrder(response);
                props.backToMain();
                // const data = {
                //     razorpayPaymentId: response.razorpay_payment_id,
                //     razorpayOrderId: response.razorpay_order_id,
                //     razorpaySignature: response.razorpay_signature,
                // };

                // const result = await axios.post("http://localhost:5000/payment/success", data);

                // alert(result.data.msg);
            },
            prefill: {
                name: "",
                email: "",
                contact: parseInt(localStorage.getItem('userPhone')),
            },
            theme: {
                color: "#F16521",
            },
            modal: {
                "ondismiss": () => {
                    props.backToMain();
                }
            },
            method: {
                netbanking: true,
                card: true,
                wallet: true,
                upi: true,
                paylater: true
            },


        };
        setTimeout(function () {
            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
        }, 1000);
    }

    const displayPaytm = () => {
        setLoading(true);
        var config = {
            "root": "",
            "style": {
                // "bodyBackgroundColor": "#ffffff",
                // "bodyColor": "",
                // "themeBackgroundColor": "#0FB8C9",
                // "themeColor": "#ffffff",
                // "headerBackgroundColor": "#F16521",
                // "headerColor": "#ffffff",
                "errorColor": "",
                "successColor": "",
                "card": {
                    "padding": "",
                    "backgroundColor": ""
                }
            },
            "data": {
                "orderId": paymentData.order,
                "token": paymentData.token,
                "tokenType": "TXN_TOKEN",
                "amount": paymentData.amount /* update amount */
            },
            "payMode": {
                "labels": {},
                "filter": {
                    "exclude": []
                },
                "order": [
                    "CARD",
                    "UPI",
                    "BALANCE",
                    "CC",
                    "DC",
                    "PPBL",
                    "PPI"
                ]
            },
            "website": "WEBSTAGING",
            "flow": "DEFAULT",
            "merchant": {
                "mid": paymentData.mid,
                "redirect": false
            },
            "handler": {
                // On Payment Success or FAIL
                "transactionStatus": function transactionStatus(paymentStatus) {
                    paymentStatus.STATUS = "TXN_AWAITING";
                    // props.placeVoucherOrder(paymentStatus);
                    // props.placeVoucherOrder(paymentStatus);
                    props.backToMain();
                    document.getElementById('app-close-btn').click();
                },
                // Call on Closed Payment Interface
                "notifyMerchant": function notifyMerchant(eventName, data) {
                    console.log("Closed");
                    props.backToMain();
                    setLoading(false);
                }
            }
        };

        if (window.Paytm && window.Paytm.CheckoutJS) {
            // initialze configuration using init method
            window.Paytm.CheckoutJS.init(config).then(function onSuccess() {
                props.hideLoader();
                console.log('Before JS Checkout invoke');
                // after successfully update configuration invoke checkoutjs
                window.Paytm.CheckoutJS.invoke();
            }).catch(function onError(error) {
                console.log("Error => ", error);
            });
        }
    }

    if (paymentData.token != "" && loading === false) {
        displayPaytm();
    }

    const options = Object.keys(paymentOptions).map(function(key) {
        return (
          <FormControlLabel
            key={key}
            value={key}
            control={<Radio />}
            label={paymentOptions[key].fsName}
          />
        );
    });
    let paymentMethods = <div className="text-left payment-methods">
        <FormControl component="fieldset">
            <RadioGroup aria-label="gender" name="gender1" value={paymentMethod} onChange={(event)=>{
                setPaymentMethod(event.target.value)
                setShowSelectionError(false)
            }}>
                {options}
            </RadioGroup>
        </FormControl>
        {showSelectionError?<div className="error">Select one payment method to continue</div>:''}
    </div>
    
    // if(loading){
    //     return(
    //         <div className="AppDashboard markAsReadScreen">
    //             <div className="screenPadding">
    //                 <div className="markAsReadBack">
    //                     <span style={{ 'fontSize': '24px', 'marginTop': '-2px' }} className="material-icons-outlined" onClick={props.backToMain}>keyboard_backspace</span>
    //                 </div>
    //                 <div className="MainTitle">
    //                     <div className="onlineOfflineTitle">Payment Method</div>
    //                 </div>

    //                 <div className={'payment-form-handler open'}>
    //                     <div id="payment-form">
    //                         {loading ? (<img src="https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif" />) : ('')}
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     )
    // }

    return (
        <>
            { isPaymentPopupEnable ?<ContinuePopup popupButtonText={"Continue Payment"}
            popupTitle={"Payment Method"}
            secondLineText={paymentMethods}
            popupContent=""
            handleClose={()=>{
                if(paymentMethod!=""){
                    setShowPaymentMethodPopup(false);
                    payFromKey(paymentMethod);
                }else{
                    setShowSelectionError(true)
                }
            }} 
            openDialog={isShowPaymentMethodPopup} />:''}

            <div id="payment-form">
                {loading ? (<img src="https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif" />) : ''}
            </div>
        </>
    )
}