import React from "react";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import config from '../config';

export default function MoreCashbackPopup(props) {
    var popupTitle = '';
    var foGlobalSetting = JSON.parse(localStorage.getItem('foGlobalSetting'));

    var myCashPer = config.getMyCashPer();
    var amountPer700 = (700 * parseInt(config.getMyCashPer())) / 100;
    var totalBillAmount = 700 + amountPer700;
    var myCashPopupHtml = '<div class="my-cash-popup">\
      <div class="title"><b>MORE '+config.getGlobalCashbackString().toUpperCase()+', MORE CASHBACK</b></div>\
      <div class="body">\
      '+config.getGlobalCashbackString()+' is our Loyalty Program you can<br/>\
      Redeem it as Real Cash, equal to '+config.getMyCashPer()+'% of<br/>\
      your cashback on every bill, & it doubles<br/> \
      after every 3 or 4 Bills, ie 40% then 80%.<br/>\
                          An Example<br/>\
      Coupon “Upto 50% Off & 10% Cashback\
      </div>\
      <div class="tbl">\
        <div><div>You Shop for Shoes Priced</div><div> 	- Rs 10,000</div></div>\
        <div><div>Discount say 30% (Saved)</div><div>	- Rs 3,000</div></div>\
        <div><div>Balance paid by you (Paid)</div><div> 	- Rs 7,000</div></div>\
        <div><div>Cashback Now Due @10%</div><div> 	- Rs 700</div></div>\
        <div><div><b>'+config.getGlobalCashbackString()+' Added ('+config.getMyCashPer()+'% X '+amountPer700+')</b></div><div> 	<b>- Rs 140</b></div></div>\
        <div><div>Total Cash (After Validation)</div><div> 	- Rs. '+totalBillAmount+'</div></div>\
      </div>\
      <div class="credit-in">(Credit in your Chosen Wallet/Account)</div>\
    </div>';

    if(props.cashbackType==="info-instant-cash"){
      var myCashPopupHtml = `<div class="my-cash-popup infoPopup">\
        <div class="title"><b>WHAT IS “INSTANT” CASHBACK ?</b></div>\
        <div class="body">\
          Cashback that you can withdraw as Real Cash <br> credits in about 60-90 days,  But we Give it Instantly !
        </div>
        <ul style="padding-left: 1.525rem;text-align: left;">\
          <li>Visit any Store, Offline or Online and opt to pay using a Voucher code.</li>\
          <li>Find the Store and your ${config.getGlobalCashbackString()} gets auto applied as discount  % on Voucher amount.</li>\
          <li>Buy voucher code to pay & get the discount in your wallet as Instant Cashback.</li>\
          <li>Use this cash to make future vouchers Free with us, or withdraw once Rs. 1000 Min.</li>\
          <li>Your bonus cash can expire anytime once
          the campaign budget is over, so use it fast.</li>\
          </ul>\
        <div class="body">Make sure you always have MyCash Balance!</div>
        <div class="credit-in">(Cashback is Over & Above most Ongoing Offers)</div>\
      </div>`;
    }

    if(props.cashbackType==="info-my-cash"){
      var myCashPopupHtml = `<div class="my-cash-popup infoPopup">\
        <div class="title" style="font-size: 15px;"><b>CONVERT ${config.getGlobalCashbackString().toUpperCase()} TO REAL CASHBACK</b></div>\
        <ul style="padding-left: 1.525rem;text-align: left;">\
          <li>${config.getGlobalCashbackString()} is auto applied to discount Vouchers.</li>\
          <li>Buy Code & Get discount as Instant Cashback.</li>\
          <li>Share the code at Instore cashier or at online store “Pay By Voucher” option on checkout.</li>\
        </ul>\
        <div class="body" style="text-align: left;font-size: 14px;margin: 5px auto;max-width: 260px;">\
          <b>Pan India Across</b>\
          <ul style="padding-left: 1.525rem;text-align: left;margin-top:0px;font-size: 12px;">\
            <li>+17,000 Nearby Brand Stores (Offline)</li>\
            <li>+1000 Online Stores & Food Orders</li>\
            <li>+10,000 Nearby Restaurants (Discount)</li>\
          </ul>\
        </div>\
        <div class="body" style="margin-bottom: 5px;">For Restaurants, Just Pay bills & to redeem your MyCash against the 10%-30% Instant Discounts</div>\
        <div class="body">Use your cashback to increase discounts on future Vouchers with us, or withdraw once Rs. 1000 Min.</div>\
        <div class="credit-in">(Cashback is Over & Above most Ongoing Offers)</div>\
      </div>`;
    }

    if(props.cashbackType==="bonus-cash"){
      var myCashPopupHtml = `<div class="my-cash-popup infoPopup">\
        <div class="title" style="font-size: 15px;"><b>BONUS CASH</b></div>\
        <div class="body" style="margin-bottom: 5px;">Use it to shop, Unused will expire<br/>
        without notice once the budget is<br/>
        used by other shoppers to shop</div>
      </div>`;
    }

    if(props.cashbackType==="info-pending-cash"){
      var myCashPopupHtml = `<div class="my-cash-popup infoPopup">\
        <div class="title" style="font-size: 15px;"><b>WHAT IS PENDING CASHBACK.</b></div>\
        <ul style="padding-left: 1.525rem;text-align: left;">\
          <li>No Instant Cashback coupon for your brand ? No worries, we offer usual “Cashback” coupons.</li>\
          <li>Don’t need to buy Voucher codes here, Just click and reach the Online Store to shop as usual</li>\
          <li>Avail all the discounts offered as usual & we show you the best Exclusive Deals here also.</li>\
          <li>Once you pay, the brands confirms you have come via our platform, & we send a Tracking message, in just 5 Mins or upto 36 Hrs max.</li>\
          <li>If no return or exchange done, the brand will confirm & you get a Validation message.</li>\
          <li>Cashback is then credited within 7-10 days, & we will redeem your MyCash against this credit.</li>\
        </ul>\
        <div class="body">Make sure you always have MyCash Balance!</div>\
        <div class="credit-in">(Cashback is Over & Above most Ongoing Offers)</div>\
      </div>`;
    }

    if(props.cashbackType==="infoPopup"){
      var myCashPopupHtml = `<div class="my-cash-popup infoPopup">\
      <div class="body">\
      <p>REDEEM & USE <span class="primary-color">${config.getGlobalCashbackString()}</span> (Loyalty Cash) to get</p>\
      <p><b>Instant Discounts 10%-25% (Save Extra) on</b></p>\
      <p>+12,000 Restaurants Pan India 15% - 25%<br/>\
      +27,000 InStore Outlets Pan India 5% - 20%<br/>\
      +200 Online Brand Stores 5% - 20%</p>\
      <p>On Cashbacks, you get <span class="primary-color">${config.getMyCashPer()}%</span> Cashback EXTRA <br/>\
      added ‘on top’, if you have <span class="primary-color">${config.getGlobalCashbackString()}</span> Balance.</p>\
      <p>To Earn <span class="primary-color">${config.getGlobalCashbackString()}</span></p>\
      <p>Use online Food Coupons to Order Delivery<br/>\
      from your Favorite’s or Swiggy Or Zomato etc.<br/>\
      Better Deals & Rs <span class="primary-color">100 – 500</span> ${config.getGlobalCashbackString()} on order.</p>\
      <p>(Over & Above most ongoing promotion)</p>\
    </div>`;
    }

    if(props.cashbackType==="cashback"){
      var myCashPopupHtml = '<div class="my-cash-popup Offline-store">\
      <div class="title"><b>CASHBACKS</b><div style="font-size:12px">Always Exact & Extra</div></div>\
      <div class="body">\
      Click our coupon to visit any store <br/>\
      and shop, we get commission that <br/> \
      we share with you as Cashbacks ! <br/><br/>\
      This is always extra over all other<br/>\
      Discounts & Card Cashbacks that<br/> \
      that you may get from the store.<br/><br/> \
      This amount will credit into your<br/> \
      chosen E-wallet / UPI id / Bank Ac<br/> \
      Within ~ 20 to 30 days, after your<br/> \
      Return or Xchange option is over\
    </div>';
    }

    if(props.cashbackType==='install-register'){
      var popupTitle = 'EARN OR BUY ' + config.getGlobalCashbackString().toUpperCase();
      var myCashPopupHtml = '<div class="my-cash-popup Offline-store">\
                        <div class="body">\
                          <span style="font-size:14px !important;">\
                            You Need '+config.getGlobalCashbackString()+' to Avail Offers.<br/>\
                            Complete a Task or Simply Buy it<br/>\
                          </span>\
                        </div>\
                      </div>';
    }
    
    if(props.cashbackType==="visit-eatery"){
      popupTitle = "RESTAURANTS - INSTANT DISCOUNT";
      var fsString = props.cashbackType.split('-')[1]=='red'?'Offline':'Online';
      var myCashPopupHtml = '<div class="my-cash-popup">\
        <div class="body">\
        You have Rs '+(foGlobalSetting.fiUsrTotalMyCash)+' '+config.getGlobalCashbackString()+' Balance<br/>\
        Pay your bills using “ DineOut ” Pay !<br/>\
        Apply our copied code, Get 5% Extra<br/>\
        You don’t need reservation, Just Pay\
      </div>';
    }

    
    if(props.cashbackType.split('-')[0]==="voucher"){
      var fsString = props.cashbackType.split('-')[1]=='red'?'Offline':'Online';
      var popupTitle = fsString + ' STORE';
      var myCashPopupHtml = '<div class="my-cash-popup">\
        <div class="body">\
        <div><b>INSTANT CASHBACK</b></div><br/>\
        Convert your Rs '+(foGlobalSetting.fiUsrTotalMyCash)+' '+config.getGlobalCashbackString()+' Balance,\
        to Real Cash when you shop using our brand vouchers'+'.<br/>\
        <div style="text-align:left;max-width: 260px;margin: 3px auto;">\
          1) Find the Store ‘Instant Cashback’ Voucher in List or Search option.<br/>\
          2) Your Rs '+(foGlobalSetting.fiUsrTotalMyCash)+' '+config.getGlobalCashbackString()+' will auto apply to give you Maximum Cashback.<br/>\
          3) Buy voucher code & get the ‘Instant’ Cashback in your wallet.<br/>\
          4) '+((fsString==='Online')?'Apply the voucher code on the Brand site checkout / getway.':'Share the voucher code with the store Checkout / Cashier')+'<br/>\
          5) Use the cashback on any future purchases of any vouchers.<br/>\
          </div>\
      </div>';
    }
    
    return (
        <Dialog
          classes={{root:'popupMaxWidth '+props.cashbackType}}
          open={props.openDialog}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <div className="mobyPopup mycash-popup-design-for-wallet continuePopupDesign">
            <DialogTitle id="alert-dialog-slide-title">{popupTitle ? popupTitle :props.popupTitle}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description" dangerouslySetInnerHTML={{__html: myCashPopupHtml}}>
                </DialogContentText>
                {props.secondLineText!==""?
                <DialogContentText id="alert-dialog-slide-description">
                  {props.secondLineText}
                </DialogContentText>
                :''}
              </DialogContent>
              <DialogActions>
                <Button onClick={props.handleClose} variant="contained" color="primary">
                  {props.popupButtonText}
                </Button>
              </DialogActions>
          </div>
        </Dialog>
    );
}