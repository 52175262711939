import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import config from './config';
import { Link  } from 'react-router-dom';

import { LazyLoadImage } from '@tjoskar/react-lazyload-img';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: 151,
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
}));

export default function MapItemList(props) {

  var foGlobalSetting = JSON.parse(localStorage.getItem('foGlobalSetting'));

  const classes = useStyles();
  var cardClass = 'singleMapCard '+ props.cardClass; 
  if(parseInt(localStorage.getItem('fiBannerAdId'))===parseInt(props.marker.fiAdId)){
    cardClass = cardClass+' ActiveBannerAd';
    localStorage.removeItem('fiBannerAdId');
  }

  if(props.marker.fsAdType==='Bank Offer'){
    var moreAdLink = '/bank-offer-details/'+props.marker.fiAdId+'/'+props.marker.foLocationList[0].fiLocationId;
  }else if(props.marker.fsPinColor==="red-voucher"){
    var moreAdLink = '/voucher-details/'+props.marker.fiAdId+'/'+props.marker.foLocationList[0].fiLocationId;
  }else if(props.marker.fsPinColor==="green" && props.marker.fsMarkIsUsedFlow==='buy-mycash'){
    var moreAdLink = '/buymycash-details/'+props.marker.fiAdId+'/'+props.marker.foLocationList[0].fiLocationId;
  }else{
    //add-details
    var moreAdLink = '/start-quiz/'+props.marker.fiAdId+'/'+props.marker.foLocationList[0].fiLocationId;
  }

  var fbIsMyCash = props.marker.fbIsMyCash;
  var popupType = fbIsMyCash=="yes"?'mycash':'cashback';
  if(props.marker.fsMarkIsUsedFlow==='offline-hybrid-green' || props.marker.fsMarkIsUsedFlow==='offline-hybrid-green'){
    fbIsMyCash = "yes";
    popupType = "visit-eatery";
  }else if(props.marker.fsMarkIsUsedFlow==='install-register'){
    popupType = 'install-register';
  }

  var offDiscountPrice = "";
  if(props.marker.fsFlatCashBack==="" && props.marker.fsDiscountUpTo===""){
      offDiscountPrice = <div>Rs.{props.marker.fiQuizReward}{props.marker.fiSecondReward > 0?<span> - Rs.{props.marker.fiSecondReward}</span>:''}</div>;
  }else{
    if(props.marker.fsDiscountUpTo!=="" && props.marker.fsMarkIsUsedFlow!=="buy-mycash"){
      if(props.marker.fsPinColor==="red-voucher" && props.marker.fsMarkIsUsedFlow==="voucher-green" || props.marker.fsMarkIsUsedFlow==="voucher-red"){
        let fiTotalInstantCash = JSON.parse(localStorage.getItem('foGlobalSetting')).fiTotalInstantCash;
        if (fiTotalInstantCash > 0 && props.marker.fiDefVouDenomination > 0) {
          offDiscountPrice = 'Exact <span class="zoomIt">'+props.marker.fsDiscountUpTo+'</span> Off';
        } else {
          offDiscountPrice = 'Upto <span class="zoomIt">'+props.marker.fsDiscountUpTo+'</span> Off';
        }
      }else if(props.marker.fsMarkIsUsedFlow==='offline-hybrid-green' || props.marker.fsMarkIsUsedFlow==='offline-hybrid-green' ){
        offDiscountPrice = 'Exact <span class="zoomIt">'+props.marker.fsDiscountUpTo+'</span> Off';
      }else{
        offDiscountPrice = 'Upto <span class="zoomIt">'+props.marker.fsDiscountUpTo+'</span> Discount';
      }
    }

    if(props.marker.fsPinColor==="red-voucher" && props.marker.fsMarkIsUsedFlow==="voucher-red"){
      offDiscountPrice = offDiscountPrice + ' + Extra <span class="zoomIt">'+props.marker.fsFlatCashBack+'</span> Insta Cash, Use <b style="margin-left:5px">'+config.getGlobalCashbackString()+'</b>';
    }else if((props.marker.fsMarkIsUsedFlow==='offline-direct-green' || props.marker.fsMarkIsUsedFlow==='offline-hybrid-green')){
      offDiscountPrice = offDiscountPrice + ' + <span class="zoomIt">'+props.marker.fsFlatCashBack+'</span> Extra,Use <b style="margin-left:2px">'+config.getGlobalCashbackString()+'</b>';
    }else if(props.marker.fsPinColor==="red-voucher" && props.marker.fsMarkIsUsedFlow==="voucher-green"){
      offDiscountPrice = offDiscountPrice + ' + <span class="zoomIt">'+props.marker.fsFlatCashBack+'</span> Insta Cash, Use <b style="margin-left:5px">'+config.getGlobalCashbackString()+'</b>';
    }else if(props.marker.fsPinColor==="green" && props.marker.fsMarkIsUsedFlow==="buy-mycash"){
      offDiscountPrice = offDiscountPrice + ' Buy Now <span class="zoomIt">'+props.marker.fiQuizReward+'</span> '+config.getGlobalCashbackString().toUpperCase()+', Pay <span class="zoomIt">Rs '+props.marker.fiMyCashPayableAmt+'</span> /- Only';
    }else{
      if(props.marker.fsFlatCashBack!==""){
        if(offDiscountPrice!=""){
          offDiscountPrice = offDiscountPrice+' + ';
        }
        offDiscountPrice = offDiscountPrice + ' <span class="zoomIt">'+props.marker.fsFlatCashBack+'</span> '+config.getCashbackString(fbIsMyCash) + ', Use <b style="margin-left:5px">'+config.getGlobalCashbackString()+'</b>'
      }
    }

    if(props.marker.fsUptoText!==""){
        let fsUptoText = props.marker.fsUptoText;
        fsUptoText = fsUptoText.replace(/{discountupto}/g, '<span class="zoomIt">'+props.marker.fsDiscountUpTo+'</span>');
        fsUptoText = fsUptoText.replace(/{cashbackupto}/g, '<span class="zoomIt">'+props.marker.fsFlatCashBack+'</span>');
        fsUptoText = fsUptoText.replace(/{cashbackamt}/g, '<span class="zoomIt">'+props.marker.fsFlatCashBack+'</span>');
        fsUptoText = fsUptoText.replace(/{mycash}/g, config.getGlobalCashbackString());
        offDiscountPrice = fsUptoText;
    }
    
    var showHelpPopup = true;
    if(fbIsMyCash!=="yes" && localStorage.getItem('fbIsHideCashbackHelp')==="true"){
      showHelpPopup = false;
    
    }
    let style = {};
    if(props.marker.fiAdCouponType===1){ style.justifyContent = 'center'; }

    offDiscountPrice = <div className="offerTextOnListing" style={style}>
        <div className='AdOfferText' dangerouslySetInnerHTML={{__html: offDiscountPrice}} ></div>
        <div className='help-alignment-handle'>
          {props.marker.fiAdCouponType===0 && props.marker.fsPinColor!=="red-voucher" && showHelpPopup?<span onClick={()=>{ props.openMyCashPopup(popupType)}} className="material-icons-outlined help-icon">help</span>:''}
          {props.marker.fsPinColor==="red-voucher"  && props.marker.fsMarkIsUsedFlow==='voucher-green'?<span onClick={()=>{ props.openMyCashPopup("voucher-green")}} className="material-icons-outlined help-icon">help</span>:''}
          {props.marker.fsPinColor==="red-voucher"  && props.marker.fsMarkIsUsedFlow==='voucher-red'?<span onClick={()=>{ props.openMyCashPopup("voucher-red")}} className="material-icons-outlined help-icon">help</span>:''}
        </div>
      </div>;
  }
  

  /* Green Ad Quiz Flow */
  var QuizFlow = <div onClick={()=>props.directMoveOnCouponScreen(props.marker.fiAdId)} className="greenOnlineButton MuiPaper-elevation1">
    <div>
      <span>Online<br/>Coupon</span>
      <span className="material-icons-outlined">keyboard_arrow_right</span>
    </div>
  </div>;


if(props.marker.fsMarkIsUsedFlow==='install-register'){
  QuizFlow = <div onClick={()=>props.directMoveOnCouponScreen(props.marker.fiAdId)} className="greenOnlineButton MuiPaper-elevation1">
      <div>
        <span>ONLINE<br/>TASK</span>
        <span className="material-icons-outlined">keyboard_arrow_right</span>
      </div>
    </div>
}

  if(props.marker.fsMarkIsUsedFlow==='buy-mycash'){
    QuizFlow = <Link to={moreAdLink} className="searchItemList">
      <div className="greenOnlineButton MuiPaper-elevation1">
        <div>
          <span>BUY NOW</span>
          <span className="material-icons-outlined">keyboard_arrow_right</span>
        </div>
      </div>
    </Link>
  }

  if(props.marker.fbIsQuizFlow || foGlobalSetting.fbIsQuizFlow){
    QuizFlow = <Link to={moreAdLink} className="searchItemList">
      <div className="greenOnlineButton MuiPaper-elevation1">
        <div>
          <span>Select<br/>Coupon</span>
          <span className="material-icons-outlined">keyboard_arrow_right</span>
        </div>
      </div>
    </Link>
  }

  if(props.marker.fsPinColor==='red' || props.marker.fsMarkIsUsedFlow==='offline-hybrid-green' || props.marker.fsMarkIsUsedFlow==='offline-direct-green' || props.marker.fsMarkIsUsedFlow==='offline' ){
    var QuizFlow = <div onClick={()=>props.directMoveOnCouponScreen(props.marker.fiAdId)} className="greenOnlineButton MuiPaper-elevation1" style={{'backgroundColor':"var(--red-color)"}}>
      <div className='offline-red-pin'>
        <span>Offline<br/>Coupon</span>
        <span className="material-icons-outlined">keyboard_arrow_right</span>
      </div>
    </div>;

    if(props.marker.fsMarkIsUsedFlow==='offline-hybrid-green' || props.marker.fsMarkIsUsedFlow==='offline-direct-green'){
      QuizFlow = <div onClick={()=>props.directMoveOnCouponScreen(props.marker.fiAdId)} className="greenOnlineButton MuiPaper-elevation1" style={{'backgroundColor':"var(--red-color)"}}>
      <div className='offline-red-pin'>
        <span>Offline<br/>Eatery</span>
        <span className="material-icons-outlined">keyboard_arrow_right</span>
      </div>
    </div>;
    }

    if(props.marker.fbIsEnableRedPinQuiz){
      QuizFlow = <Link to={moreAdLink} className="searchItemList"> 
        <div className="greenOnlineButton MuiPaper-elevation1" style={{'backgroundColor':"var(--red-color)"}}>
          <div className='offline-red-pin'>
            <span>Offline<br/>Coupon</span>
            <span className="material-icons-outlined">keyboard_arrow_right</span>
          </div>
        </div>
      </Link>
    }
  }

  if(props.marker.fsPinColor==="red-voucher"){
    if(props.marker.fsMarkIsUsedFlow==="voucher-red"){
      QuizFlow = <Link to={moreAdLink} className="searchItemList">
      <div className="greenOnlineButton MuiPaper-elevation1"  style={{'backgroundColor':"var(--red-color)"}}>
        <div>
          <span>Offline<br/>Voucher</span>
          <span className="material-icons-outlined">keyboard_arrow_right</span>
        </div>
      </div>
    </Link>
    }else{
      QuizFlow = <Link to={moreAdLink} className="searchItemList">
      <div className="greenOnlineButton MuiPaper-elevation1">
        <div>
          <span>Online<br/>Voucher</span>
          <span className="material-icons-outlined">keyboard_arrow_right</span>
        </div>
      </div>
    </Link>
    }
  }
  
  if(props.marker.fsAdType==='Bank Offer'){
    var QuizFlow = <Link to={moreAdLink} className="searchItemList">
      <div className="greenOnlineButton MuiPaper-elevation1" style={{'backgroundColor':"var(--yellow-color)"}}>
        <div>
          <span>Select<br/>Offer</span>
          <span className="material-icons-outlined">keyboard_arrow_right</span>
        </div>
      </div>
    </Link>
  }

  var instantDiscount = '';
  if(props.marker.fsMarkIsUsedFlow==="offline-hybrid-green" || props.marker.fsMarkIsUsedFlow==="offline-direct-green"){
    instantDiscount = <div className='timeLineContent'><span class="offline-coupon instantDiscount">INSTANT DISCOUNT</span></div>;
  }else if(props.marker.fsPinColor==="red-voucher"){
    instantDiscount = <div className='timeLineContent'><span class="offline-coupon instantDiscount">INSTANT CASHBACK</span></div>;
  }else if(props.marker.fsFlatCashBack===""){
    instantDiscount = <div className='timeLineContent'><span class="offline-coupon instantDiscount">AMAZON BEST DEALS</span></div>;
  }else if(props.marker.fsPinColor==="green" && props.marker.fsMarkIsUsedFlow==='buy-mycash') {
    instantDiscount = <div className='timeLineContent'><span class="offline-coupon instantDiscount">BUY LOYALTY CASH</span></div>;
  }else if(props.marker.fsPinColor==="green" && props.marker.fsMarkIsUsedFlow==='install-register') {
    instantDiscount = <div className='timeLineContent'><span class="offline-coupon instantDiscount">EARN {config.getGlobalCashbackString().toUpperCase()}</span></div>;
  }else{
    instantDiscount = <div className='timeLineContent'><span class="offline-coupon instantDiscount">CASHBACK COUPON</span></div>;
  }

  var LazyPlaceholder = config.getProviderSetting().fsLazyLoad;
  console.log(props.marker.fsMarkIsUsedFlow);
  return (
    <div className={cardClass}>
      {props.marker.fsPinColor==='green'?
        <Card className={classes.root}>
          
              <div className="displayFlex">
              <div className='offer-image'>
                <h3>{props.marker.fsAdName}</h3>
                <div><LazyLoadImage  defaultImage={LazyPlaceholder} image={config.getImageUrl(props.marker.fsAdLogo)} /></div>
              </div>
              <div className={classes.details}>
                  <CardContent className={classes.content}>
                      <div className="rightMapCardDetails">
                          {instantDiscount}
                          {(props.marker.fsMarkIsUsedFlow==='buy-mycash')
                          ?<div className='offer-list-subText' style={{'justifyContent':'center','fontWeight':'600'}}>
                              <div className="address"> <span class="zoomIt">{props.marker.fiQuizReward}</span> {config.getGlobalCashbackString().toUpperCase()} Credit - Pay <span class="zoomIt">Rs {props.marker.fiMyCashPayableAmt}</span> /- Only</div>
                          </div>:
                          <div className='offer-list-subText'>
                            <div className="address">{props.marker.fsProductName}</div>
                            <div className="offerLeft">{props.marker.fiOfferLeft} Left</div>
                          </div>}
                          <div className="adAmountOnList">
                            {offDiscountPrice}
                          </div>
                          {props.marker.fsAdType==='ENGAGED'?
                            <div className="greenOnlineButton MuiPaper-elevation1 grayCoupon">
                              <div>
                                <span>Engaged</span>
                                <span className="material-icons-outlined">keyboard_arrow_right</span>
                              </div>
                          </div>
                          :
                            QuizFlow
                          }
                      </div>
                  </CardContent>
              </div>
          </div>
      </Card>
      :
      <Card className={classes.root}>
          
              <div className="displayFlex">
              <div className='offer-image'>
                <h3>{props.marker.fsAdName}</h3>
                <LazyLoadImage  defaultImage={LazyPlaceholder} image={props.marker.fsAdType!=='Bank Offer'?config.getImageUrl(props.marker.fsAdLogo):props.marker.fsAdLogo} />
              </div>
              <div className={classes.details}>
                  <CardContent className={classes.content}>
                      <div className="rightMapCardDetails"> 
                          {instantDiscount}
                          <div className='offer-list-subText'>
                            <div className="address">{props.marker.fsProductName}</div>
                            {props.marker.fsAdType!=='Bank Offer'?<div className="offerLeft">{props.marker.fiOfferLeft} Left</div>:''}
                          </div>
                          <div className="adAmountOnList">
                            {props.marker.fsAdType!=='Bank Offer'?offDiscountPrice:''}
                          </div>
                          {props.marker.fsAdType==='ENGAGED'?
                              <div className="greenOnlineButton grayCoupon">
                                <div>
                                  <span>Engaged</span>
                                  <span className="material-icons-outlined">keyboard_arrow_right</span>
                                </div>
                            </div>
                          :
                            QuizFlow
                          }
                      </div>
                  </CardContent>
              </div>
          </div>
          
      </Card>
      }
    </div>
  );
}